<template lang="pug">
    switcher(
        :status="delete_filter_status"
        activeColor="bg-red-500"
        :title="DELETE_TITLE"
        @on-switch="setDeleteFilterStatus"
    )
</template>

<script>
//Components
import Switcher from '@/components/common/switchers/style-1';
//Config
import { SWITCHERS_VALUES } from '@/configs/names.js';
//Store
import { RootGetterTypes, RootActionTypes } from '@/store/types';

export default {
    name: "DeleteSwitcher",
    components: {
        'switcher': Switcher,
    },
    computed: {
        SWITCHERS_VALUES() {
            return SWITCHERS_VALUES
        },
        DELETE_TITLE() {
            return { 
                    on: this.SWITCHERS_VALUES.can_delete, 
                    off: this.SWITCHERS_VALUES.cannot_delete,
                }
        },
        delete_filter_status() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        }
    },
    methods: {
        setDeleteFilterStatus() {
            this.$store.dispatch(`${RootActionTypes.SetDeleteFilterStatus}`, 
                { 
                    status: !this.delete_filter_status 
                }
            )
        }
    }
}
</script>