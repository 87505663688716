//Sidbar Navigation
const DELETE_CONFIRMATION = {
    text: 'Подтвердите удаление ',
    ok: 'Удалить',
    cancel: 'Отмена',
}


export {
    DELETE_CONFIRMATION,
}