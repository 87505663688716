<template lang="pug">
    construction-block.mb-2.felx.justify-between.items-center
        .container.w-auto
            slot(name="statistics")
            slot(name="filters")
        .container.w-auto.flex.items-center.gap-5
            .container
                slot(name="buttons")
            .container
                slot(name="switchers")
                    delete-switcher
</template>

<script>
//Components
import ConstructionBlock from '@/components/common/block';
import DeleteSwitcher from './delete-switcher.vue';
//Store
import { RootActionTypes } from '@/store/types';

export default {
    name: "BaseFilter",
    components: {
        'construction-block': ConstructionBlock,
        'delete-switcher': DeleteSwitcher,
    },
    methods: {
        resetDeleteFilter() {
            this.$store.dispatch(`${RootActionTypes.SetDeleteFilterStatus}`, { status: false })
        }
    },
    beforeDestroy() {
        this.resetDeleteFilter()
    }
}
</script>