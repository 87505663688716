<template lang="pug">
    .inline-block.p-4.border.rounded.rounded-sm.bg-white.shadow-sm.inline-flex.align-center
        .w-5.flex.align-center
            inline-svg(:src="ICONS.folder")
        template(v-if="!isEditName")
            span(@click="onName" class="inline-block pl-2 pr-4 text-sm cursor-pointer hover:text-accent") {{ categoryData.name }}
        template(v-else)
            .block
                text-input(class="pl-3 pr-3" :value="categoryData.name" @value="onNameInput")
        .flex.align-center.justify-end.gap-4.pl-3
            template(v-if="!isEditName")
                inline-svg.block.w-4.cursor-pointer(
                    @click="onAddSubCategory({ id: categoryData._id })" 
                    :src="ICONS.plus" 
                    :title="BUTTONS_VALUES.add_subcategory"
                )
                template(v-if="hasDescendants || hasDescendantsLocal")
                    inline-svg.block.w-4.cursor-pointer.transform(
                        @click="isShowSubcategories ? onHideSubcategories() : onShowSubcategories()" 
                        :src="ICONS.circle_right" 
                        :title="BUTTONS_VALUES.show_subcategories" 
                        :class="{'rotate-90': isShowSubcategories}"
                    )
                template
                    inline-svg.m-auto(
                        :src="ICONS.bin" 
                        @click="() => { if (deleteFilter) {onDeleteConfirmation()} }"
                        :fill-opacity="deleteFilter ? 1 : 0.25"
                    )
            template(v-else)
                img.block.w-4.cursor-pointer(@click="onSave({ id: categoryData._id, name: name })" :src="ICONS.checkmark" :title="BUTTONS_VALUES.save")
                img.block.w-4.cursor-pointer(@click="onCancel" :src="ICONS.cancel_circle" :title="BUTTONS_VALUES.cancel")
</template>

<script>
//Components
import TextInput from '@/components/common/forms/text-input';
//Configs
import { ICONS } from '@/configs/icons'
import { BUTTONS_VALUES } from '@/configs/names'
//Store
import { CompanyNameSpace, CompanyActionTypes, CompanyMutationTypes, CompanyGetterTypes } from '@/store/company/types';
import { RootGetterTypes } from '@/store/types';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';
//Mixins
import { deleteConfirmation, closeModalDialog } from '@/mixins/modals';

export default {
    name: "BaseCompanyItem",
    mixins: [deleteConfirmation, closeModalDialog],
    components: {
        'text-input': TextInput,
    },
    props: {
        categoryData: {
            type: Object,
            default: () => {},
        },
        hasDescendants: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isEditName: false,
            name: '',
            isShowSubcategories: false,
            hasDescendantsLocal: false,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
        parentWithNoChildren() {
            return this.$store.getters[`${CompanyNameSpace}/${CompanyGetterTypes.GetParentWithNoChildren}`]
        }
    },
    methods: {
        getUserAuthData() {
            return getUserAuthData()
        },
        onName() {
            this.isEditName = !this.isEditName
        },
        onNameInput(value) {
            this.name = value
        },
        onCancel() {
            this.isEditName = false
            this.name = ''
        },
        async onSave({ id, name }) {
            await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.EditCompany}`, { 
                    id,
                    params: { name } 
                }
            )

            this.isEditName = false
        },
        async onAddSubCategory({ id }) {
            const {data} = await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.AddCompany}`, {
                params: { 
                    name: 'subcategory', 
                    slug: 'subcategory',
                    order: 1,
                    author: this.getUserAuthData().id,
                    parent: id,
                }
            })

            this.onGetCategories({ params: { _id: data.id } })
            this.isShowSubcategories = true
            this.hasDescendantsLocal = true
        },
        async onGetCategories({ params = {}, readonly = false }) {
            const data = await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.GetCompanies}`, { params, readonly })
            return data
        },
        async onShowSubcategories() {
            await this.onGetCategories({ params: { parent: this.categoryData._id } })
            this.isShowSubcategories = true
        },
        async onHideSubcategories() {
            const { data } = await this.onGetCategories({ params: { "ancestors._id": this.categoryData._id }, readonly: true })
            const id_collection = data.data.map(item => {
                    return item._id
                }
            )
            
            this.$store.commit(`${CompanyNameSpace}/${CompanyMutationTypes.RemoveCompaniesFromStore}`, { id_collection: id_collection })
            this.isShowSubcategories = false
        },
        async onDeleteConfirmation() {
            this.deleteConfirmation({ confirm: 'onDeleteCategory' })
            
        },
        async onDeleteCategory() {
            await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.DeleteCompany}`, { 
                    id: this.categoryData._id,
                    parent: this.categoryData.parent,
                }
            ) 
            
            this.closeModalDialog()
        }
    },
    watch: {
        parentWithNoChildren: {
            async handler(val) {
                //Если у данной категории больше нет подкатегорий (после удаления), то убираем кружочек со стрелочкой
                if (val.id === this.categoryData._id) {
                    const { data } = await this.onGetCategories({ params: { "ancestors._id": this.categoryData._id }, readonly: true })
                    if (!data.data.length) {
                        this.isShowSubcategories = false
                        this.hasDescendantsLocal = false
                    }
                }
            },
            deep: true,
        }
    }
}
</script>