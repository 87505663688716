<template lang="pug">
    .w-5.h-5.rounded.rounded-full.cursor-pointer(:class="activeClass" @click="onSwitch" :title="activeTitle")
        template(v-if="icon")
            img(:src="icon")
</template>

<script>
export default {
    name: "SwitcherStyle1",
    props: {
        color: {
            type: String,
            default: 'bg-gray-300',
        },
        activeColor: {
            type: String,
            default: 'bg-accent',
        },
        title: {
            type: Object,
            default: () => (
                {on: 'on', off: 'off'}
            ),
        },
        status: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String
        }
    },
    computed: {
        activeClass() {
            let className = this.color
            if (this.status) className = this.activeColor
            return className
        },
        activeTitle() {
            let titleValue = this.title.off
            if (this.status) titleValue = this.title.on
            return titleValue
        },
    },
    methods: {
        onSwitch() {
            this.$emit('on-switch')
        }
    }
}
</script>