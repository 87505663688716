<template lang="pug">
    .container
        label.text-xs {{ label }}
        input(
            :type="inputType" 
            class="h-10 w-full mt-1 p-3 caret-slate-300 border rounded border-slate-300"
            @input="onValue"
            :value="value"
            :placeholder="placeholder"
        )
</template>

<script>
export default {
    name: "TextInput",
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
        },
    },
    methods: {
        onValue({ target }) {
            this.$emit('value', target.value)
        }
    }
}
</script>