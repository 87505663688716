<template lang="pug">
    div
        filters
            slot(slot="filters")
                span Name Search - 
                span Date Range Search
            slot(slot="buttons")
                regular-button(:value="BUTTONS_VALUES.add_root_category" @click="onAddRootCategory")
        company
</template>

<script>
//Components
import Company from '@/components/views/company';
import Filters from '@/components/common/filters';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
import { CompanyNameSpace, CompanyActionTypes, CompanyMutationTypes } from '@/store/company/types';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';

export default {
    name: "CompanyListPage",
    components: {
        'filters': Filters,
        'company': Company,
        'regular-button': RegularButton,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
    },
    mounted() {
        this.onGetCategories({ params: { parent: '%A0' } })
    },
    methods: {
        getUserAuthData() {
            return getUserAuthData()
        },
        async onAddRootCategory() {
            const {data} = await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.AddCompany}`, {
                params: { 
                    name: 'root category', 
                    slug: 'root_category',
                    order: 1,
                    author: this.getUserAuthData().id,
                }
            })

            this.onGetCategories({ params: { _id: data.id } })
        },
        async onGetCategories({ params }) {
            await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.GetCompanies}`, {params})
        }
    },
    beforeDestroy() {
        this.$store.commit(`${CompanyNameSpace}/${CompanyMutationTypes.SetEmptyCompanies}`)
    }
}
</script>