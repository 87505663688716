//Tabs Navigation
const TABS_NAVIGATION = {
    list: 'Список',
    add: 'Добавить',
    add_employee: 'Добавить сотрудника',
    employees: 'Сотрудники',
    company: 'Компания',
}

//Forms
const FORMS_LABELS = {
    name: 'имя',
    description: 'описание',
    email: 'e-mail',
    password: 'пароль',
    user_name: 'имя',
    user_lastname: 'фамилия',
    user_patronymic: 'отчество',
    user_photo: 'фото',
    user_date_of_birth: 'дата рождения',
    user_role: 'роль',
    user_status: 'статус',
    by_lastname: 'по фамилии',
    user_exists: 'пользователь есть в системе',
    add_new_user: 'добавить нового пользователя',
    by_email: 'по e-mail',
    enter_user_lastname: 'введите фамилию пользователя',
    enter_user_email: 'введите email пользователя',
    work_place: 'место работы',
    job_title: 'должность',
    enter_event_name: 'введите название события',
    list_name: 'название в списке',
    parent_event: 'родительское событие',
    title: 'заголовок',
    subtitle: 'подзаголовок',
    start_date_end_date: 'дата начала и дата окончания',
    start_date: 'дата начала',
    end_date: 'дата окончания',
    add_participants: 'добавить участников',
    date_of_birth: 'дата рождения',
    city: 'город',
    phone: 'телефон',
    text: 'текст',
    grab_files: 'Перетащите или выберите файлы для загрузки',
    attachment: 'прикрепите файлы',
    refer: 'раздел',
}

//Buttons
const BUTTONS_VALUES = {
    upload: 'загрузить',
    sign_in: 'войти',
    log_out: 'выйти',
    edit: 'редактировать',
    add: 'добавить',
    delete: 'удалить',
    add_root_category: 'добавить корневую категорию',
    add_subcategory: 'добавить подкатегорию',
    save: 'сохранить',
    cancel: 'отмена',
    show_subcategories: 'показать подкатегории',
    hide_subcategories: 'скрыть подкатегории',
    add_employee: 'добавить сотрудника',
    select: 'выбрать',
    add_event: 'добавить событие',
    copy_to_clipboard: 'копировать в буфер обмена',
}

//Switchers
const SWITCHERS_VALUES = {
    can_delete: 'Удаление разрешено',
    cannot_delete: 'Удаление запрещено',
    blocked: 'Заблокирован',
    active: 'Активен',
    accepted: 'Одобрен',
    not_accepted: 'Неодобрен',

}

//Pages
//Pages[Files]
const FILES_LIST_TABLE_HEADERS = [
    '#',
    'превью',
    'тип',
    'название',
    'описание',
    'размер',
    'дата создания',
    'статус',
    'удалить',
]
//Pages[Users]
const USERS_LIST_TABLE_HEADERS = [
    'фото',
    'имя',
    'фамилия',
    'отчество',
    'email',
    'роль',
    'статус',
    'дата регистрации',
    'удалить',
]
//Pages[Employee]
const EMPLOYEE_LIST_TABLE_HEADERS = [
    'фото',
    'имя',
    'фамилия',
    'отчество',
    'место работы / должность',
    'email',
    'статус',
    'удалить',
]
//Pages[Events]
const EVENTS_LIST_TABLE_HEADERS = [
    '',
    'название',
    'дата начала',
    'дата окончания',
    'информация',
    'участники',
    'статус',
    'тип',
    'родитель',
    'подсобытия',
    'редактировать',
    'удалить',
]

//Pages[UserPosts]
const USER_POSTS_LIST_TABLE_HEADERS = [
    '#',
    'ф.и.о',
    'дата рождения',
    'город',
    'телефон',
    'заголовок',
    'текст',
    'файлы',
    'подробнее',
    'одобрено',
    'статус',
    'удалить',
]

export {
    TABS_NAVIGATION,
    FORMS_LABELS,
    BUTTONS_VALUES,
    SWITCHERS_VALUES,
    FILES_LIST_TABLE_HEADERS,
    USERS_LIST_TABLE_HEADERS,
    EMPLOYEE_LIST_TABLE_HEADERS,
    EVENTS_LIST_TABLE_HEADERS,
    USER_POSTS_LIST_TABLE_HEADERS,
}