// import Vue from 'vue'
import { DELETE_CONFIRMATION } from '@/configs/modals'

const deleteConfirmation = {
    methods: {
        deleteConfirmation({item = '', confirm = ''}) {
            this.$modal.show('dialog', {
                text: `${DELETE_CONFIRMATION.text} ${item}`,
                buttons: [
                    {
                        title: DELETE_CONFIRMATION.ok,
                        handler: () => {
                            this[confirm]()
                        }
                    },
                    {
                        title: DELETE_CONFIRMATION.cancel,
                        handler: () => {
                            this.$modal.hide('dialog')
                        }
                    }
                ]
            })
        }
        
    }
}

const closeModalDialog = {
    methods: {
        closeModalDialog() {
            this.$modal.hide('dialog')
        }
    }
}

const _openModal_ = {
    methods: {
        _openModal_({ name }) {
            this.$modal.show(name)
        }
    }
}

const _closeModal_ = {
    methods: {
        _closeModal_({ name }) {
            this.$modal.hide(name)
        }
    }
}

export {
    deleteConfirmation,
    closeModalDialog,
    _openModal_,
    _closeModal_
}