<template lang="pug">
    .container
        Button(
            class="p-3 cursor-default bg-gray-100 min-w-fit whitespace-nowrap" 
            :class="{'bg-accent text-white cursor-pointer': isActive, 'text-gray-300': !isActive}" 
            :value="value" @click="isActive ? $emit('click') : false"
        )
</template>

<script>
import Button from '@/components/common/buttons/button';

export default {
    name: "DefaultButton",
    props: {
        value: {
            type: String,
        },
        isActive: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        Button
    }
}
</script>