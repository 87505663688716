<template lang="pug">
    button(class="w-full text-sm border rounded" @click="$emit('click')") {{ value }}
</template>

<script>
export default {
    name: "RegularButton",
    props: {
        value: {
            type: String,
            default: () => {
                return 'click me!'
            }
        }
    }
}
</script>